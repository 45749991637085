import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupIcon from '@mui/icons-material/Group';
import SecurityIcon from '@mui/icons-material/Security';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PollIcon from '@mui/icons-material/Poll';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const location = useLocation();

    const [sidebarModules, setSidebarModules] = useState([]);

    useEffect(() => {
        // Obtener perfil de usuario del local storage
        const userProfile = localStorage.getItem('user');
        if (userProfile) {
            const parsedProfile = JSON.parse(userProfile);
            configureSidebarModules(parsedProfile.role);
        }
    }, []);

    const configureSidebarModules = (role) => {
        let modulesToShow = [];

        switch (role) {
            case 'SYSTEM':
                modulesToShow = ['Home', 'announcements', 'notifications', 'payments', 'residents', 'security', 'subscriptions', 'surveys', 'visitors', 'user-management', 'addresses'];
                break;
            case 'ADMIN':
                modulesToShow = ['Home', 'announcements', 'notifications', 'payments', 'residents', 'security', 'surveys', 'visitors', 'user-management','addresses'];
                break;
            case 'USER':
                modulesToShow = ['Home', 'announcements', 'notifications', 'payments', 'security', 'surveys', 'visitors'];
                break;
            default:
                modulesToShow = ['Inicio'];
                break;
        }

        setSidebarModules(modulesToShow);
    };

    const getTittle = (module) => {
        let tittle = '';

        switch (module) {
            case 'Home':
                tittle = 'Inicio';
                break;
            case 'announcements':
                tittle = 'Anuncios';
                break;
            case 'notifications':
                tittle = 'Notificaciones';
                break;
            case 'payments':
                tittle = 'Pagos';
                break;
            case 'residents':
                tittle = 'Residentes';
                break;
            case 'security':
                tittle = 'Eventos de seguridad';
                break;
            case 'subscriptions':
                tittle = 'Suscripciones';
                break;
            case 'surveys':
                tittle = 'Encuestas';
                break;
            case 'visitors':
                tittle = 'Visitantes';
                break;                
            case 'user-management':
                tittle = 'Administracion de usuarios';
                break;
                case 'addresses':
                    tittle = 'Direcciones';
                    break;
            default:
                tittle = 'Inicio';
                break;
        }

        return tittle
    };

    const getIcon = (module) => {
        switch (module) {
            case 'Inicio':
                return <HomeIcon />;
            case 'Anuncios':
                return <AnnouncementIcon />;
            case 'Notificaciones':
                return <NotificationsIcon />;
            case 'Pagos':
                return <PaymentIcon />;
            case 'Residentes':
                return <GroupIcon />;
            case 'Eventos de seguridad':
                return <SecurityIcon />;
            case 'Suscripciones':
                return <SubscriptionsIcon />;
            case 'Encuestas':
                return <PollIcon />;
            case 'Visitantes':
                return <PersonIcon />;            
            case 'Administracion de usuarios':
                return <AccountBoxRoundedIcon />;
            default:
                return null;
        }
    };

    const drawer = (
        <div>
            <Toolbar />
            <List>
                {sidebarModules.map((module, index) => (
                    <ListItem
                        button
                        key={getTittle(module)}
                        component={Link}
                        to={module === 'Home' ? '/' : `/${module.toLowerCase().replace(/\s/g, '-')}`}
                        selected={location.pathname === (module === 'Home' ? '/' : `/${module.toLowerCase().replace(/\s/g, '-')}`)}
                    >
                        <ListItemIcon>{getIcon(getTittle(module))}</ListItemIcon>
                        <ListItemText primary={getTittle(module)} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <nav aria-label="mailbox folders">
            {isSmUp ? (
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    {drawer}
                </Drawer>
            ) : (
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            )}
        </nav>
    );
};

export default Sidebar;
export { drawerWidth };
