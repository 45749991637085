// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LoadingProvider } from './context/LoadingContext'; // Importa el LoadingProvider

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoadingProvider> {/* Envuelve tu aplicaci�n con LoadingProvider */}
            <App />
        </LoadingProvider>
    </ThemeProvider>
);
