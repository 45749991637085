import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, timeout = 60000) => {
  const timer = useRef(null);

  const resetTimer = () => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(onIdle, timeout);
  };

  useEffect(() => {
    // Detectar eventos de actividad
    const handleActivity = () => resetTimer();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);

    resetTimer();

    return () => {
      // Limpiar eventos y timer al desmontar el componente
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
      if (timer.current) clearTimeout(timer.current);
    };
  }, [onIdle, timeout]);

  return resetTimer;
};

export default useIdleTimer;
