// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useLoading } from '../context/LoadingContext';
import config from '../config';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { startLoading, stopLoading } = useLoading();

    const handleLogin = async () => {
        startLoading();
        try {
            console.log('Backend URL:', config.backendUrl);
            console.log('Socket URL:', config.socketUrl);
            const response = await axios.get(`${config.backendUrl}/login?password=${password}&username=${email}`);
            if (response.data.codigo === 200) {
                const { token, resultset } = response.data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(resultset));
                navigate('/');
            } else {
                // manejar errores de autenticación
                alert('Su cuenta esta inactiva, favor de contactar al administrador');
            }
        } catch (error) {
            console.error(error);
        } finally {
            stopLoading();
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
            <Typography variant="h4" component="h1">Login</Typography>
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
            />
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleLogin}>
                Login
            </Button>
        </Box>
    );
};

export default Login;
